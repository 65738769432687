<template>
    <div class="row">
        <h2 class="section-heading m-b-md"> {{$t('navigation.side.vehicle_search')}}</h2>
        <MessageCard :alertType="'indicator-warning'" :alertTitle="$t('global.important_message')" :alertText="$t('vehicle_search.alert_text')" class="m-b-md"/>
        <div class="col-xl-4 m-b-xxl">
            <div class="form-group">
                <label for="registration" class="form-label"> {{$t('events.filters.vehicle_registration')}} <span class="text-danger"> * </span> </label>
                <input class="form-control" id="registration" v-model="registration" placeholder="Enter Registration">
            </div>
            <div class="p-v-xs">
                <!-- TODO - Loading sign -->
                <button class="btn btn-primary m-r-sm" @click="searchVehicle"> {{$t('claims.processing.search')}} </button>
                <button class="btn btn-secondary" @click="clearFilter"> {{$t('global.clear_filter')}} </button>
            </div>
        </div>

        <!-- <div class="card">
            <div class="card-body"> -->
                <h1 v-if="searching">  {{searchedClients}} of {{totalClients}} customers checked...</h1>
                <table class="table table-borderless"> 
                    <thead>
                        <tr>
                            <th> {{$t('global.client').toUpperCase()}} </th>
                            <th> {{$t('vehicle_search.no_of', {text: 'Events'}).toUpperCase()}} </th>
                            <th> {{$t('vehicle_search.no_of', {text: 'Claims'}).toUpperCase()}} </th>
                            <th> {{$t('vehicle.blacklisted').toUpperCase()}} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="vehicleOverview.length < 1">
                            <td colspan="4" class="no-data-container">{{$t('global.no_data')}}</td>
                        </tr>
                        <tr v-for="obj of vehicleOverview" :key="obj.index">
                            <td> {{obj.client}} </td>
                            <td> {{obj.noOfEvents}} </td>
                            <td> {{obj.noOfClaims}} </td>
                            <td v-if="obj.blacklist.length > 0"> 
                                <i class="text-success material-icons" style="vertical-align: middle;"> done </i> <span> (Reason: {{obj.blacklist[0].reason}})</span>
                            </td>
                            <td v-else>
                                <i class="material-icons text-danger"> close </i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            <!-- </div>
        </div> -->

        <h5 class="sub-heading m-b-sm"> {{$t('vehicle_search.system_logs')}} </h5>
        <table class="table table-borderless">
            <thead>
                <tr>
                    <th> {{$t('labels.description').toUpperCase()}} </th>
                    <th> {{$t('manage_evidence.labels.timestamp').toUpperCase()}} </th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="logs.length < 1">
                    <td colspan="2" class="no-data-container"> {{$t('global.no_data')}} </td>
                </tr>
                <tr v-for="log in logs" :key="log.id">
                    <td> {{log.description}} </td>
                    <td> {{log.created_at}} </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import MessageCard from '../../components/MessageCard.vue';
import axios from 'axios';
import authHeader from '../../services/auth-header';
import {DateTime} from 'luxon';

export default {
    name: 'VehicleSearch',
    components: {
        MessageCard
    },
    data() {
        return {
            registration: "",
            searching: false,
            logs: [], //stores all the logs,
            clients: [], //vehicle info against that client i.e. clients that the user have access to 
            totalClients: 0,
            searchedClients: 0,
            vehicleOverview: []
        }
    },
    mounted() {
        this.clients = this.$store.state.auth.user.clients;
        this.totalClients = this.clients.length;
    },
    methods: {
        async searchVehicle() {
            this.searching = true;
            this.logs = [];
            this.vehicleOverview = [];
            // this.totalClients = this.$store.state.auth.user.clients.length;
            this.searchedClients = 0;

            axios.get(`https://api.varsanpr.com/api/vehicle/${this.registration}/locate?client_id=0`, {headers: authHeader()})
            .then(async response => {
                this.logs = response.data.logs;

                this.logs.forEach(log => {
                    log.created_at = DateTime.fromSeconds(log.created_at).toFormat('dd/MM/yyyy HH:mm:ss');
                });

                for await (let client of this.clients) {
                    this.searchedClients++;
                    await this.searchVehicleForClient(client); //pass client
                }

                this.searching = false;
               
                // this.clients.forEach(async client => {
                //     await searchVehicleForClient(client.id);
                //     this.searchedClients ++;
                // });
                // for await (let client of this.clients) {
                //     await this.searchVehicleForClient(client.id);
                //     this.searchedClients++;
                // }
                // this.clients.forEach(client => {
                //     this.searchVehicleForClient(client.id);
                // })
            })
            .catch(error => {
                console.log(error);
            })
        },
        async searchVehicleForClient(client) {
            return axios.get(`https://api.varsanpr.com/api/vehicle/${this.registration}/locate?client_id=${client.id}`, 
            {headers: authHeader()})
            .then(response => {
                console.log('response', response);
                if(response.data.events > 0 || response.data.claims.length > 0 || response.data.blacklist.length > 0){
                    this.vehicleOverview.push({client: client.name, noOfEvents: response.data.events, noOfClaims: response.data.claims.length, blacklist: response.data.blacklist.length > 0});
                }

                // this.vehicleOverview = response.data;
                // this.searchedClients++;
            })
            .catch(error => {
                this.$error("Failed to locate vehicle for " + client.name, error);
            })
        },
        clearFilter() {
            this.registration = '';
            this.logs = [];
            this.vehicleOverview = [];
        }
    }
}
</script>

<style scoped>
    .sub-heading {
        font-size: 18px;
    }
</style>